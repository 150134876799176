.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.diagramStatus {
  color: #ffffff;
  font-size: large;
  /* font-weight: bold; */
  /* font-size: calc(5px + 2vmin); */

}

.App-header {
  background-color: #44B0D6;  /* B4DFEE 44B0D6 */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: #4e74b6;
}

.cloud {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login {

}

.dashrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1.0pc;
}

#chakra-modal-modal {  
  max-height: 100%;
  overflow: scroll;
}


.App-link {
  color: #61dafb;
}

.react-flow__attribution {
  visibility: hidden;
}

.react-flow__handle {
  visibility: hidden;
}

.nodestyle {
  background-color: "#d4d4d4";
  color: "black";
}
.react-flow__node react-flow__node-default nopan selectable {
  /* background-color: #a9a9a9; */
}

.react-flow__node.selected {
  /* background-color: #d4d4d4; */
  /* font-weight: bold; */
  /* border: 5px; */
  /* color: #black; */
  /* border-color: red;  */
}
path.react-flow__edge-path {
  stroke-width: 3;
}

path.react-flow__edge-path:hover {
  stroke-width: 6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
